
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0;
  box-shadow: inset 0 0 0px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #fff; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #fff; 
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #000;
  font-style: italic;
  font-weight: 500;
}
::-moz-placeholder { /* Firefox 19+ */
  color: black;
}
:-ms-input-placeholder { /* IE 10+ */
  color: black;
}
:-moz-placeholder { /* Firefox 18- */
  color: black;
}

html {
  height: -webkit-fill-available;
  background: #fff;
}

.linkgeenlink {
  text-decoration: none;
}

.body {
  margin: 0 auto;
  padding: 0;
}
.app{ 
  background: rgb(252, 141, 156, 0);
  margin:  auto;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: #000;
}

.tweet {
  height: 300px;
  width: 300px;
  background: white;
  border-radius: 30px;
}

.dag {
  padding:8px;
  font-size:10px;
  margin-left:5px;
  margin-right:5px;
  margin-bottom:5.7px;
  color: #000;
  height: 60px;
  width: 60px;
  background: #fff;
  border-radius: 10px;
  align-items: center;
}

.soorten {
  padding:4px;
  margin-bottom:20px;
  color: #000;
  height:65.6px;
  width: 250px;
  background: #fff;
  border-radius: 10px;
  align-items: center;
  font-size: 10px;
}

.fixedwidth {
  padding-left: 70px;
}

.vak {
  padding:4px;
  margin-bottom:20px;
  color: #000;
  height: 37px;
  width: 250px;
  background: #fff;
  border-radius: 10px;
  align-items: center;
  font-size: 7px;
}

.sticky {
  position:fixed;
  right:0;
  bottom:0;
  margin:0;
  width:150px;
  height:60px;
  background:url(http://heymelissa.nl/img/nuonline.png) no-repeat bottom right;
  background-size:100%;
}

.vakje {
  margin-left:25px;
  margin-bottom:-10px;
  color:white;
  height: 50px;
  width: 60px;
  border-radius: 10px;
  align-items: center;
  font-size: 9px;
}

.red{
  color: red;
  font-size: 50px;
}

.formulier {
  margin-top:100px;
  padding: 20px;
  padding-top: 20px;
  min-height: 300px;
  width: 300px;
  font-size: 12px;
  color: #000;
  background: #fff;
  border-color: black;
  border-radius: 30px;
  border: 2px;
  border-style: solid;
}

.formulierStart {
  padding: 20px;
  padding-top: 20px;
  min-height: 180px;
  width: 300px;
  font-size: 12px;
  background: rgba(255, 192, 203, 0.6);
  border-radius: 30px;
}

.samenvatting {
  display:flex;
  padding-right: 25px;
}

hr.reservering {
  border: 0.5px solid #000;
}

.datum {
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.akkoord {
  width: 100px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}

.textblock {
  padding: 5px;
  width: 300px;
}

.centertext {
  margin-top:-20px;
  padding-left:30px;
  padding-right:30px;
}


.centertextpink {
  margin-top:-20px;
  margin-left:20px;
  margin-right:20px;
  padding-left:30px;
  padding-right:30px;
  padding: 10px;
  background: #fff;
  border-color: black;
  border-style: solid;
  color: #000;
  border-radius: 5px;
}

.checkmark {
  width:30px;
}

div {
  color:white;
}

input {
  padding: 10px;
  background: #fff;
  border-color: black;
  border-style: solid;
  color: #000;
  font-weight: 800;
  border-radius: 5px;
  border: 0px;
  width: 70%;
  height: 40px;
}

textarea {
  padding: 10px;
  background: #fff;
  border-color: black;
  border-style: solid;
  color: #000;
  font-weight: 800;
  border-radius: 5px;
  border: 0px;
  width: 70%;
  height: 100px;
  max-width: 70%;
  max-height: 300px;
}

select {
  padding: 10px;
  background: #fff;
  border-color: black;
  border-style: solid;
  color: #000;
  font-weight: 800;
  border-radius: 5px;
  border: 0px;
  width: 70%;
  height: 40px;
}

button {
  min-width: 120px;
  height: 30px;
  color: #000;
  font-weight: 700;
  background: #fff;
  border-radius: 20px;
  border: 0px;
  border-color: black;
}

input[type=submit] {
  width: 120px;
  height: 30px;
  color: #000;
  font-weight: 700;
  background: #fff;
  border-radius: 20px;
  border: 0px;
  border-color: black;
}

input[type=text] {
  padding: 10px;
  background: #fff;
  border-color: black;
  border-style: solid;
  color: #000;
  font-weight: 800;
  border-radius: 5px;
  border: 0px;
  width: 70%;
  height: 20px;
}

input[type=email] {
  padding: 10px;
  background: #fff;
  border-color: black;
  border-style: solid;
  color: #000;
  font-weight: 800;
  border-radius: 5px;
  border: 0px;
  width: 70%;
  height: 20px;
}

label {
  font-size:14px;
  font-weight: 200;
}
